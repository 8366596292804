html, body {
  height: 100%;
  background-color: black;
  margin:0;
  overflow-x: hidden;
  overflow: -moz-scrollbars-vertical;
    /* overflow-y: scroll; */
}
.bg_img_height{
  height:1500px;
  margin-top: -340px;
}
.paragraphs_font{
  font-family: 'Sora', sans-serif;
}
.para_color{
  color: #93A2B3;
}
.link-text{
  color: #0041B1;
}
.bg-dark-theme{
  background-color: #000B15;
}
input[type="file"] {
  display: none;
}
.custom-file-upload{
  display: inline-block;
  cursor: pointer;
  color: wheat;
  background-color: #FF8A00;
}

.custom-csv-upload{
  display: inline-block;
  cursor: pointer;
  color: wheat;
  background: linear-gradient(90deg, rgba(16,171,254,1) 30%, rgba(0,115,238,1) 90%);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url('/src/assets/arrow_select.svg');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 15px;
}
.language-select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/src/assets/small-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 15px;
}

.language-select-black-icon{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/src/assets/down.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 8px;
}
.gradient_button{
  background: linear-gradient(90deg, rgba(16,171,254,1) 30%, rgba(0,115,238,1) 90%);
}
.greetings{
  border-left: 2px solid;
  border-image: 
    linear-gradient(
      to bottom, 
      rgba(16,171,254,1), 
      rgba(0,115,238,1)
    ) 1 100%; 
}
.text_gold{
  color:#E07900
}
.button_gold{
  background-color: #E07900;
}
.select-box{
  height:50px;
}
.gradient_text{
  background: linear-gradient(to right, #10ABFE 30%, #0073EE 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lets_go_text{
  background: linear-gradient(to right, #10ABFE 12%, #0073EE 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Login_left_pannel{
  background-color: #091724;
  color: white;
}
.opacity_blue{
  background-color: #111f2c
}
.field_bg-blue{
  background-color: #000B15;
}
.inputfield {
  font-weight: 300;
  width: 100%;
  height: 45px;
  font-family: 'Sora', sans-serif;
  background-color: #000B15;
  border: 1px solid #1E293B;
}

.was-validated {
  border: 1px solid red!important;
}
.was-validated::-webkit-input-placeholder {
  color: red;
}
.border{
  border: 1px solid #1E293B;
}
.button-border{
  border: 1px solid #1E293B;
}
.text-area{
  border: 1px solid #1E293B; 
}
.PhoneInput{
  height: 45px;
  width: 100%;
  font-size: 16px;
  background-color: #000B15;
  border: 1px solid #1E293B;
}
.PhoneInputInput{
  height: 43px;
  width: 100%;
  background-color: #000B15!important;
  outline:none;
  color:white;
}
.PhoneInputCountry{
  font-size: 18px;
  border-right: 1px solid #1E293B;
  padding-right: 10px;
  padding-left: 10px;
}
.PhoneInputCountryIconUnicode, .PhoneInputCountryIcon{
  color: white;
}
.PhoneInputCountrySelectArrow{
  color: white;
}
.input_select{
  width: 70%;
  height: 30px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  background: url('/src/assets/call.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  margin-top: 8px;
}
.landing-screen{
  background: url('/src/assets/landing_page_bg.png') no-repeat;
  width: 100%;
  height: 702px;
  display: block;
  background-size: cover;
}
.dashboard_card_main{
  display: flex;
  width: 460px;
  height: 152px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}
.dashboard_card_main:hover {
  transform: translate(0, -8px);
  box-shadow: 0 0 11px rgba(35, 87, 160, 0.7);
}
.dashboard_card_bg_color{
  color: white;
  background-color: #091724;
}
.dashboard_card_inner_right{
  padding: 0px 0px 0px 20px;
}
.card_title{
  font-size: 26px;
  font-weight: 700;
}

.card_description{
  font-size: 13px;
}
.user_registration_inner{
  display: flex;
}

.show{
  display: block;
}

.hide{
  display: none;
}
.left_bordered{
  height: 50px;
  border-left: 1px solid #1E293B;
  margin-left: 15px;
}
.tabs_right{
  padding-left: 30px;
}
.right-position{
  right: -760px;
}
.w-99{
  width:75%;
}
.w-200{
  width: 500px;
  height:auto;
}
.animated{
  -webkit-animation: rotate 550s normal linear infinite;
  animation: rotate 550s normal linear infinite;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.bg_color{
  background-color: #00080F;
}

.table_bg_color_black th, td {
  background-color: #091724!important;
  color:white!important;
}
.table_rows_black  .ant-table-row > td{
  background-color: #091724!important;
  color:white!important;
}
.table_bg_color_white th, td {
  background-color: white !important;
  color:black!important;
}
.table_rows_white  .ant-table-row > td{
  background-color: white !important;
  color:black!important;
  border-bottom: 1px solid black!important;
}

.table_rows_black .ant-table-placeholder > td{
  background-color: #091724!important;
}

.table_rows_black .ant-table-placeholder > td .ant-empty-description{
  color:white!important;
}

.tabs_bg-white{
  background-color: #E3F2FE;
  color: #0073EE;
}
.bg-for-light{
  background-color: #FCFCFC;
}


.bg-import-button{
  background-color: #1E3A2C;
}
.bg-add-new-user-button{
  background-color: #5A4123;
}
.bg-add-new-company-button{
  background-color: #0C2541;
}
.bg-light-blue-button{
  background-color: #0073EE;
}
.bg-light-green-button{
  background-color: #20744A;
}

.modal-container-overlay{
  background-color: rgba(0,0,0,0.6);
  z-index: 300;
}
.summary-container{
  background-color: rgba(0,11,21,0.7);
}
.card-board{
  border: 1px solid #E6E6E6;
}
.border-right-only{
  border-right: 2px solid #E6E6E6;
}

.border-dashed{
  border: 2px dashed #E6E6E6;
}

.landign-screen-text-animated{
  background: linear-gradient(
        to right,
        rgba(16,171,254,1) 20%,
        rgba(0,115,238,1) 30%,
        rgba(16,171,254,1) 70%,
        rgba(0,115,238,1) 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300% auto;
    animation: textShine 1s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
      background-position: 0% 20%;
  }
  50% {
      background-position: 0% 40%;
  }
  100% {
      background-position: 100% 0%;
  }
}

.button-dark{
  background-color: #091724;
}

.input_field_main_password{
  position: relative;
}
.show_password{
  right: 10px;
}

input::-webkit-input-placeholder  {
  color: #9E9E9E;
}

.box-shadow-sign-in{
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
-webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
-moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
}

.input_field_main_textarea textarea{
  background: #000B15;
  border: 1px solid #1E293B;
  outline: none;
  color: white;
  resize: none;
}

.box_1{
  width: 55px;
    height: 0px
}
input[type="checkbox"].switch_1{
  font-size: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 2.8em;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked{
  background: #0ebeff;
  }
  
  input[type="checkbox"].switch_1:after{
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
          box-shadow: 0 0 .25em rgba(0,0,0,.3);
  -webkit-transform: scale(.7);
          transform: scale(.7);
  left: -3px;
  top:-3.4px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked:after{
  left: calc(100% - 1.4em);
  }

  .checkBox_value_color{
    color: #E92F46;
  }

  .ant-select-selector{
    background-color: #000B15!important;
    outline: none;
    color: white;
    border: none !important;
    padding: 8px 0px!important;
    padding-left: 5px!important;
  }

  .ant-select-arrow , .ant-select-clear{
    color: white!important;
  }

  .ant-select-selection-item{
    background-color: #0073EE!important;
  }
  .ant-select-selection-item-remove{
    color: white!important;
  }

  .ant-select-selection-placeholder{
  color: #9E9E9E!important;
  }

  .w-35{
    width: 35.666667%
  }
  .w-49{
    width:49%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(32, 32, 32); 
    background: #01111F; 
    /* border-radius: 10px; */
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0073EE!important; 
    /* border-radius: 10px; */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0073EE; 
  }

  :where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px!important;
}

/* .draggable-table .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .ant-table-thead .ant-table-cell{
  background: white!important;
} */
.ant-table-expanded-row .ant-table-cell{
  padding: 0px!important;
}
.tabs-bg{
  /* background-color: rgb(229, 242, 255); */
  background-color: #FCFCFC;
}

.active-tab{
  border-bottom: 2px solid #0073EE;
}
.tab-pannel{
  border-left: 2px solid #0073EE; 
}


input[type="checkbox"].switch_11{
	font-size: 12px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	width: 3em;
	height: 1.5em;
	background: #ddd;
	border-radius: 3em;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_11:checked{
	background: #11c700;
  }
  
  input[type="checkbox"].switch_11:after{
	position: absolute;
	content: "";
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	background: #fff;
	-webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
	        box-shadow: 0 0 .25em rgba(0,0,0,.3);
	-webkit-transform: scale(.7);
	        transform: scale(.7);
	left: 0;
  top:0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_11:checked:after{
	left: calc(100% - 1.5em);
  }

  .z-700{
    z-index: 700;
  }
  .z-800{
    z-index: 800;
  }
  .side-navbar{
    width: 0;
    overflow-x: hidden;
  }
  .width-250{
    width:300px;
    animation: widthIncreasae 0.5s ease-in-out;
  }

  @keyframes widthIncreasae {
    0% {
        width: 0px;
    }
    100% {
        background-position: 300px;
    }
  }

  .side-navbar a{
    transition: 1s;
  }

  .custom-h-10 { height: 10%; }
  .custom-h-90 { height: 90%; }

  .f-18{
    font-size: 18px;
  }

  .tabs-input-border{
    border: 1px solid #E6E6E6;
    width: 300px;
    outline: none;
  }

  .tabs-select-border{
    border: 1px solid #E6E6E6;
    width: 300px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/src/assets/down.png');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 10px;
  }

  .opt-input{
    border: 1px solid #8B9CAA;
    width: 200px;
    outline: none;
  }

  .cond-select-icon{
    -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/src/assets/down.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 7px;
  }

  /* .ant-table-pagination{
    width: 30%;
    margin: 15px auto!important;
  } */
  .ant-pagination li {
    background-color: white!important;
  }
  .ant-pagination-options-size-changer{
    border: 1px solid #8B9CAA!important
  }

  .ant-pagination-options-size-changer .ant-select-selector{
    background-color: white!important;
  }
  
  .ant-pagination-options-size-changer .ant-select-selector .ant-select-selection-item{
    background-color: white!important;
  }
  .side-navbar-item:hover{
  background-color: #111f2c
  }

  .uplaod-scvicon{
    animation: MoveUpDown 5s linear infinite;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(1ch);
  }
}

.heading-light-gray{
  color:#9E9E9E;
}

select:invalid,
select option[value=""] {
  color: #999999;
  font-family: 'Sora', sans-serif;
}

.ant-select-selection-placeholder {
  color: #999999!important;
  font-family: 'Sora', sans-serif!important;
  font-size: 16px!important;
}

.mrt-2{
  margin-top:-2px;
}

.row-reverse{
  flex-direction: row-reverse!important;
}

.pl-10{
  padding-left:10px;
}
.w-11{
  width:11%;
}

.inputfield-white{
  font-weight: 300;
    width: 100%;
    height: 45px;
    font-family: 'Sora', sans-serif;
}

.test:after {
  content: '\2807';
  font-size: 30px;
}

.mobile-view{
  border: 4px solid black;
  border-radius: 8px;
}

.golden-view{
  border: 4px solid rgb(114, 114, 113);
  border-radius: 6px;
}

.head-border{
  border-top-left-radius: 2px 2px;
  border-top-right-radius: 2px 2px;
}

.activity-input-white{
  font-weight: 300;
    width: 100%;
    height: 30px;
    font-family: 'Sora', sans-serif;
}

.acitivity-form-inputblack {
  font-weight: 300;
  width: 100%;
  height: 30px;
  font-family: 'Sora', sans-serif;
  background-color: #000B15;
  border: 1px solid #1E293B;
}

.activiy-select-black{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/src/assets/down.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 7px;
}
.activiy-select-gray{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/src/assets/sel_gray.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 9px;
}
:where(.css-dev-only-do-not-override-2rgkd4).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 8px 0px 0px 0px;
}
.ant-collapse-expand-icon{
  margin-top: 5px;
}
.activity-mddl{
  padding: 0px;
}
.activity-mddl .ant-select-selector{
  background-color: white !important;
    outline: none;
    color: black;
    border: none !important;
    padding: 0px 0px !important;
    padding-left: 0px !important;
    outline: none!important;
}
.edit-button{
  background-color: #F6F6F6;
}

.boarder-description{
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
}
.org-details div:nth-child(3) p:nth-child(1) {
  border-right: 1px solid #E6E6E6;
}

.org-details div:nth-child(6) p:nth-child(1) {
  border-right: 1px solid #E6E6E6;
}

.org-details div:nth-child(1) p:nth-child(2) {
  border-right: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
}
.org-details div:nth-child(3) p:nth-child(2) {
  border-right: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
}
.org-details div:nth-child(5) p:nth-child(2) {
  border-bottom: 1px solid #E6E6E6;
}
.org-details div:nth-child(4) p:nth-child(2) {
  border-right: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
}
.org-details div:nth-child(6) p:nth-child(2) {
  border-right: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
}

.site-form div:nth-child(5){
  width:100%;
}

.border-bottom{
  border-bottom: 1px solid #091724!important;
}

:where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-tbody >tr >td{
  border-bottom: none !important;
}

.icon-white{
  color:white;
}